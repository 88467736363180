<template>
  <div class="nav">
    <el-link 
      :class="getClass(0)"
      :underline="false"
      @click="handleArea(0)"
      >全部</el-link>
    <el-link
      v-for="el in cityData"
      :key="el.id"
      :class="getClass(el.id)"
      :underline="false"
      @click="handleArea(el.id)"
      >{{ el.name }}</el-link
    >
  </div>
</template>

<script>
import { listAreaByParent } from "@/api/cms/area.js";
export default {
  data() {
    return {
      cityData: [],
    };
  },
  methods: {
    getClass(id) {
      return "link " + (this.$parent.$parent.query.areaId == id ? "active" : "");
    },
    handleArea(id) {
      this.$parent.$parent.searchArea(id);
    },
    listAreaHome() {
      listAreaByParent(0).then((res) => {
        this.cityData = res;
      });
    },
  },
  created() {
    this.listAreaHome();
  },
};
</script>

<style scoped lang="scss" >
.nav {
  padding-left: 10px;

  .link {
    padding: 5px;
  }
  .active {
    color: #409eff;
  }
}

@media screen and (max-width: 600px) {
}
</style>
